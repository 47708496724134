import React, { Component } from 'react';
import "./Logo.scss";

class Logo extends Component {
    render() {
        return (
            <h2 className="Logo">Robbert Kooiman</h2>
        );
    }
}

export default Logo;